import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';
import { CctcSelect } from './cctc.selected.data.service';

@Injectable({ providedIn: 'root' })
export class CctcService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async getAll() {
        return this.httpClient
            .get<any>(this.hostnameService.VnuisAPI_Readv1 + '/bantccb/cctc/get-all', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async getSelectNamQuy() {
        return this.httpClient
            .get<any>(this.hostnameService.VnuisAPI_BaseData_Read_Staffv1 + '/staff/static/select-nam-quy', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async getCountDashboardDvCb(data: CctcSelect) {
        let params: string[] = [];
        if (data.ma_don_vi && data.ma_don_vi != 'all') params.push(`ma_don_vi=${data.ma_don_vi}`);
        if (data.nam) params.push(`nam=${data.nam}`);
        if (data.ky) params.push(`ky=${data.ky}`);
        if (data.part) params.push(`part=${data.part}`);

        return this.httpClient
            .get<any>(
                this.hostnameService.VnuisAPI_BaseData_Read_Staffv1 +
                    '/staff/static/dashboard-count-dv-cb' +
                    (params.length > 0 ? '?' + params.join('&') : ''),
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }
}
