<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div
    class="main-container"
    [dir]="dir"
    [ngClass]="{
        minisidebar: minisidebar,
        boxed: boxed,
        danger: danger,
        blue: blue,
        green: green,
        horizontal: horizontal
    }"
>
    <mat-toolbar color="primary" class="topbar relative">
        <div class="w-100 fix-width-for-horizontal">
            <button mat-icon-button (click)="snav.toggle()" class="sidebar-toggle" value="sidebarclosed">
                <mat-icon>menu</mat-icon>
            </button>

            <h2 style="margin: 5px 0 0 0px; font-weight: 600" class="header-responstive">
                <span class="web_title">{{ 'common.WEB_TITLE' | translate }}</span>
                <span class="web_title_short">{{ 'common.WEB_TITLE_SHORT' | translate }}</span>
            </h2>

            <span fxFlex></span>
            <div *ngIf="!horizontal; else horizontalheader">
                <app-vertical-header></app-vertical-header>
            </div>

            <ng-template #horizontalheader>
                <app-horizontal-header></app-horizontal-header>
            </ng-template>
        </div>
    </mat-toolbar>

    <mat-sidenav-container
        class="example-sidenav-container"
        [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
        [ngClass]="{ minisidebar: minisidebar }"
    >
        <mat-sidenav
            #snav
            id="snav"
            class="pl-xs"
            [mode]="mobileQuery.matches ? 'side' : 'over'"
            fixedTopGap="0"
            [opened]="mobileQuery.matches"
            [disableClose]="mobileQuery.matches"
            (open)="sidebarOpened = true"
            (close)="sidebarOpened = false"
        >
            <perfect-scrollbar *ngIf="!horizontal; else horizontalsidebar" class="scbar" [config]="config">
                <app-vertical-sidebar class="app-sidebar" [showClass]="status" (notify)="snav.toggle()">
                </app-vertical-sidebar>
            </perfect-scrollbar>

            <ng-template #horizontalsidebar>
                <app-horizontal-sidebar></app-horizontal-sidebar>
            </ng-template>
        </mat-sidenav>

        <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
            <div class="scroll">
                <mat-nav-list>
                    <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>
                    <mat-list-item *ngIf="!minisidebar">
                        <mat-slide-toggle color="warn" [(ngModel)]="horizontal"> Horizontal Layout</mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-slide-toggle color="warn" (change)="dir = dir == 'rtl' ? 'ltr' : 'rtl'"
                            >RTL
                        </mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-slide-toggle color="warn" [(ngModel)]="boxed">Boxed Layout</mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item *ngIf="!horizontal">
                        <mat-slide-toggle [(ngModel)]="minisidebar">Mini Sidebar</mat-slide-toggle>
                    </mat-list-item>
                    <mat-divider></mat-divider>

                    <h3 mat-subheader class="text-uppercase font-weight-bold">Colors</h3>

                    <mat-list-item>
                        <mat-checkbox
                            color="warn"
                            [(ngModel)]="danger"
                            (change)="green = blue = false"
                            class="text-danger"
                            >Red
                        </mat-checkbox>
                    </mat-list-item>

                    <mat-list-item>
                        <mat-checkbox
                            color="warn"
                            [(ngModel)]="green"
                            (change)="danger = blue = false"
                            class="text-megna"
                            >Teal Green</mat-checkbox
                        >
                    </mat-list-item>

                    <mat-list-item>
                        <mat-checkbox
                            color="warn"
                            [(ngModel)]="blue"
                            (change)="green = danger = false"
                            class="text-info"
                            >Blue
                        </mat-checkbox>
                    </mat-list-item>

                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="dark" (change)="darkClick()">Dark </mat-checkbox>
                    </mat-list-item>
                </mat-nav-list>
            </div>
        </mat-sidenav>

        <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">
            <app-breadcrumb></app-breadcrumb>
            <div class="page-content">
                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
