<style>
    body {
        background-color: white !important;
    }
</style>

<div class="login-register">
    <div class="login-register-box error-card text-center">
        <h1 style="margin: 0">404</h1>
        <h3 class="text-uppercase" style="margin: 0">{{ 'Page Not Found' | translate }}</h3>
        <p class="text-muted" style="margin: 0">
            {{ 'You seem to be trying to find this way to home' | translate }}
        </p>
        <a
            mat-raised-button
            color="primary"
            [routerLink]="['/']"
            class="btn btn-info btn-rounded waves-effect waves-light m-b-40"
            style="margin-top: 10px;"
        >
            {{ 'Back to home' | translate }}
        </a>
    </div>
</div>
