import { MCODES } from './roles.menu.utils';

export const MENU_ITEMS_DASHBOARD = {
    state: 'dashboards',
    name: 'menuitems.dashboard',
    type: 'link',
    icon: 'av_timer',
};

export const MENU_ITEMS_TAIKHOAN = {
    state: ['account', 'account-info'],
    name: 'menuitems.tai_khoan',
    type: 'link',
    icon: 'manage_account',
};

export const MENU_ITEMS_LUUTRU = {
    state: ['storage', 'files-downloaded'],
    name: 'menuitems.luu_tru',
    type: 'link',
    icon: 'archive',
};

// export const MENU_ITEMS_TIENICH = {
//     state: 'utilities',
//     name: 'menuitems.tien_ich',
//     type: 'sub',
//     icon: 'extension',
//     children: [
//         { state: ['standard-address-and-general', 'address'], name: 'menuitems.standard_address', type: 'link' },
//         { state: ['standard-address-and-general', 'general'], name: 'menuitems.standard_general_data', type: 'link' },
//         { state: 'standard-basic-data', name: 'menuitems.standard_staff', type: 'link' },
//     ],
// };

export const MENU_ITEMS_STAFF_DON_VI = {
    state: ['version-staff', 'list'],
    name: 'menuitems.staff_don_vi',
    type: 'link',
    icon: 'work',
};

export const MENU_ITEMS_STAFF_BAN = {
    state: ['version-staff-ban-tccb', 'list'],
    name: 'menuitems.staff_ban_tccb',
    type: 'link',
    icon: 'work',
};

export const MENU_ITEMS_CCTC = {
    state: ['cctc', 'list'],
    name: 'menuitems.cctc_title',
    type: 'link',
    icon: 'subdirectory_arrow_right',
};

export const MENU_ITEMS_SYNTHETIC = {
    state: ['synthetic', 'bao-cao-tccb'],
    name: 'Reporting',
    type: 'link',
    icon: 'library_books',
};

export const MENU_ITEMS: Map<string, any> = new Map<string, any>([
    [MCODES.dashboard, MENU_ITEMS_DASHBOARD],
    [MCODES.cctc, MENU_ITEMS_CCTC],
    [MCODES.staff, MENU_ITEMS_STAFF_DON_VI],
    [MCODES.staff_ban, MENU_ITEMS_STAFF_BAN],
    [MCODES.luutru, MENU_ITEMS_LUUTRU],
    // [MCODES.tienich, MENU_ITEMS_TIENICH],
    [MCODES.taikhoan, MENU_ITEMS_TAIKHOAN],
    [MCODES.bcth, MENU_ITEMS_SYNTHETIC],
]);

export const MENU_ITEMS_ORDER: string[] = [
    MCODES.dashboard,
    MCODES.cctc,
    MCODES.staff,
    MCODES.staff_ban,
    MCODES.bcth,
    MCODES.luutru,
    // MCODES.tienich,
    MCODES.taikhoan,
];
