import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/providers/language.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class GlobalErrorComponent {
    // Translate
    private language: string = 'vn';
    private languageSub!: Subscription;

    constructor(private translate: TranslateService, private languageService: LanguageService) {
        // Translate
        this.language = this.languageService.language;
        this.translate.use(this.language);
        this.languageSub = this.languageService.languageSub.subscribe((newLanguage: string) => {
            this.language = newLanguage;
            this.translate.use(this.language);
        });
    }

    ngOnDestroy(): void {
        if (this.languageSub) this.languageSub.unsubscribe();
    }
}
