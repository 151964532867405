import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './authentication/keycloak/auth.guard';
import { GlobalErrorComponent } from './authentication/global-error/error.component';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        data: { someRoles: ['admin', 'vnuhcm.tccb.chuyenvien', 'vnuhcm.tccb.lanhdaoban'] },
        children: [
            {
                path: '',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboards',
                loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
            },

            // Account
            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
            },

            // Storage
            {
                path: 'storage',
                loadChildren: () => import('./storage/files.module').then((m) => m.StorageModule),
            },

            // Version Staff
            {
                path: 'version-staff',
                loadChildren: () => import('./version-staff/version-staff.module').then((m) => m.VersionStaffModule),
            },
            {
                path: 'version-staff-ban-tccb',
                loadChildren: () =>
                    import('./version-staff-ban-tccb/version-staff.module').then((m) => m.VersionStaffBanTccbModule),
            },

            // CCTC
            {
                path: 'cctc',
                loadChildren: () => import('./cctc/cctc.module').then((m) => m.CctvModule),
            },

            // UTITLITIES
            {
                path: 'utilities',
                loadChildren: () => import('./utilities/utilities.module').then((m) => m.UtilitiesModule),
            },

            // SYNTHETIC
            {
                path: 'synthetic',
                loadChildren: () => import('./synthetic/synthetic.module').then((m) => m.SyntheticModule),
            },
        ],
    },
    // {
    //     path: '',
    //     component: AppBlankComponent,
    //     children: [
    //         {
    //             path: 'authentication',
    //             loadChildren: () =>
    //                 import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
    //         },
    //     ],
    // },
    // {
    //     path: '**',
    //     redirectTo: 'authentication/404',
    // },
    {
        path: '**',
        component: AppBlankComponent,
        children: [
            {
                path: '',
                component: GlobalErrorComponent,
            },
        ],
    },
];
