import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CctcService } from './cctc.service';

export interface CctcSelect {
    ma_don_vi: string | undefined;
    nam: string | undefined;
    ky: string | undefined;
    part: string | undefined;
}

@Injectable({ providedIn: 'root' })
export class CctcSelectedDataService {
    private _data: CctcSelect | undefined;
    private _dataSub: Subject<CctcSelect | undefined> = new Subject();

    public get data(): CctcSelect | undefined {
        return this._data;
    }
    public set data(value: CctcSelect | undefined) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<CctcSelect | undefined> {
        return this._dataSub;
    }

    constructor() {}

    trigger() {
        if (this._data) this._data = { ...this._data };
        this._dataSub.next(this._data);
    }
}
